@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

/* General reset */
body, html, #root {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  background-color: black;
}

/* Header styling */
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  padding: 20px 0;
  position: fixed;
  top: 0;
  background-color: black;
  z-index: 10;
}

/* Title styling */
.title {
  font-size: 2.8em;
  color: white;
  transition: color 2s ease;
}

.header-connected .title {
  color: green;
  animation: pulseTitle 3s infinite;
}

@keyframes pulseTitle {
  0% {
    color: green;
  }
  50% {
    color: lightgreen;
  }
  100% {
    color: green;
  }
}

/* Menu links */
.menu-link {
  font-size: 1.2em;
  color: white;
  text-decoration: none;
}

.menu-link:hover {
  cursor: pointer;
}

.passive-link {
  color: #c9d1d8;
}

/* Main network container styling */
.network-container {
  position: relative;
  width: 100vw;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

/* Pentagram SVG styling */
.pentagram {
  width: 90%;
  height: 100%;
}

/* Styling for computer icons */
.computer {
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0.7;
  transform-origin: center;
}

.computer:hover {
  opacity: 1;
}

.computer.active {
  opacity: 1;
  filter: drop-shadow(0px 0px 10px limegreen);
}

/* Line styling */
.link {
  stroke: gray;
  stroke-width: 3;
  stroke-dasharray: 6, 6;
  transition: stroke 2s ease;
}

.link.active {
  stroke: green;
  stroke-dasharray: 0;
}

/* Animation for active lines */
@keyframes pulseGreen {
  0% {
    stroke: lightgreen;
  }
  50% {
    stroke: green;
  }
  100% {
    stroke: lightgreen;
  }
}

.link.animated {
  animation: pulseGreen 3s infinite;
}

/* Footer styling */
.footer {
  position: fixed;
  bottom: 10px;
  width: 100%;
  text-align: center;
}

.footer-link {
  font-size: 1.2em;
  color: white;
  text-decoration: none;
}

.tooltip-container {
  position: relative;
  display: inline-block; /* Keeps the tooltip aligned with the link */
}

/* Tooltip styling */
.tooltip {
  position: absolute;
  top: 110%; /* Position slightly below the "Contact Us" link */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333; /* Dark gray background for readability */
  color: white;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 0.9em;
  z-index: 5;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.tooltip a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.tooltip a:hover {
  text-decoration: underline;
}

/* Modal overlay and content styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.modal-content {
  background-color: #333; /* Dark gray background */
  color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh; /* Restrict height to 80% of the viewport */
  overflow-y: auto; /* Enable vertical scroll if content exceeds max-height */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.modal-content h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.modal-content p {
  font-size: 1.1em;
  line-height: 1.6;
}

.manifesto-text {
  color: #c9d1d8;
  font-size: 16px;
}

.link-container a {
  margin: 10px;
  color: lightgreen;
  text-decoration: none;
}

/* Responsive styles for smaller screens */
@media (max-height: 900px) {
  /* Smaller computer icons and masking circles */
  .computer {
    width: 100px;
    height: 100px;
    transform: translate(-50px, -50px); /* Centering adjusted for smaller size */
  }

  .network-container {
    height: 70vh;
    padding-top: 50px;
  }

  .pentagram circle {
    r: 45; /* Smaller radius for masking circles */
  }

  /* Thinner lines */
  .link {
    stroke-width: 2;
  }

  .modal-content h1 {
    font-size: 1.5em;
  }

  .manifesto-text {
    color: #c9d1d8;
    font-size: 14px;
  }
}

/* Responsive styles for smaller screens */
@media (max-width: 600px) {
  .network-container {
    height: 70vh;
  }

  .modal-content {
    max-height: 60vh; /* Restrict height to 60% of the viewport */
  }

  /* Smaller header text */
  .title {
    font-size: 2em;
  }

  .menu-link {
    font-size: 1em;
  }

  .modal-content h1 {
    font-size: 1em;
  }

  /* Smaller computer icons and masking circles */
  .computer {
    width: 75px;
    height: 75px;
    transform: translate(-37.5px, -37.5px); /* Centering adjusted for smaller size */
  }

  .pentagram circle {
    r: 45; /* Smaller radius for masking circles */
  }

  /* Thinner lines */
  .link {
    stroke-width: 2;
  }

  .manifesto-text {
    color: #c9d1d8;
    font-size: 12px;
  }
}

@media (max-width: 450px) {
  /* Smaller header text */
  .title {
    font-size: 1em;
  }

  .modal-content h1 {
    font-size: 1em;
  }

  .menu-link {
    font-size: 0.5em;
  }

  .footer {
    font-size: 0.75em;
  }

  /* Tooltip styling */
  .tooltip {
    position: absolute;
    top: 110%; /* Position slightly below the "Contact Us" link */
    left: 50%;
    transform: translateX(-50%);
    background-color: #333; /* Dark gray background for readability */
    color: white;
    padding: 4px 6px;
    border-radius: 8px;
    font-size: 0.5em;
    z-index: 5;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  }

  .tooltip a {
    color: lightgreen;
    text-decoration: none;
    cursor: pointer;
  }

  .manifesto-text {
    color: #c9d1d8;
    font-size: 10px;
  }
}
